import React from "react";

export function LinkArrowRight({ size = "sm" }) {
  return (
    <svg
      width={size === "md" ? "23" : "20"}
      height={size === "md" ? "16" : "14"}
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6.31577V7.24655C16.9633 7.90086 15.1891 10.2539 14.936 13.5623L13.0172 13.3534C13.2063 10.4628 14.4716 8.48144 16.9216 7.59674V7.40936L0 7.75955L0 5.80277L16.9216 6.12839V5.96558C14.4744 5.08088 13.2091 3.09952 13.0172 0.187384L14.936 0C15.1891 3.30841 16.9633 5.66453 20 6.31577ZM17.4694 7.41243H17.4277H17.4694Z"
        fill="#1D299A"
      />
    </svg>
  );
}
