import React from "react";
import classNames from "./GetStartedButton.module.css";
import { useTranslation } from "react-i18next";
import { Link } from "../../../components/Link";

export function GetStartedButton() {
  const [t] = useTranslation();

  return (
    <button className={classNames.btn}>
      <Link to="/signup">{t("landing.ctaButton", "Get started — it’s free!")}</Link>
    </button>
  );
}
