import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroArea from "./HeroArea/HeroArea";
import classNames from "./Features.module.css";

import AI from "../../assets/images/Features/update-cv-automatically.gif";
import Consultantprofiles from "../../assets/images/Features/unlimited-consultant-profiles-versions.png";
import Availability from "../../assets/images/Features/availability.png";
import Analytics from "../../assets/images/Features/analytics-forecast.png";
import CRM from "../../assets/images/Features/crm.png";
import HubSpot from "../../assets/images/Features/hubspot.png";
import Slack from "../../assets/images/Features/get-notified.gif";
import Wellness from "../../assets/images/Features/wellness.png";
import Interact from "../../assets/images/Features/interact-consultant-profile.png";
import Leads from "../../assets/images/Features/share-lead.png";
import USP from "../../components/USP/USP";
import Footer from "../../components/Footer/Footer";
import Nav from "../../components/ResponsiveNav/Nav";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Explore = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const usp1 = {
    img: AI,
    title: t("explore.usp1.title", "Keep your CVs updated automatically."),
    subTitle: t(
      "explore.usp1.subtitle",
      "As a consultant, you want to secure exciting assignments and advance your career, but updating your CV can be a tedious and time-consuming task. That’s where we come in! Our automated system can generate detailed and accurate descriptions in a matter of seconds."
    ),
    description: t(
      "explore.usp1.description",
      "To generate the descriptions, we feed relevant information about the assignment, such as the client, the role, and the key competences used, into our system. This information is then used to craft a comprehensive and engaging description that showcases the skills and experience of the consultant. The automatically generated descriptions are not only more efficient to produce, but they are also more consistent in terms of style and formatting. Our system is able to train itself on the data of your company, which results in that all automatically generated assignment descriptions use your specific tone of voice."
    ),
    btn: null,
    leftImage: true,
  };
  const usp2 = {
    img: Consultantprofiles,
    title: t("explore.usp2.title", "Unlimited number of consultant profiles and versions."),
    subTitle: t(
      "explore.usp2.subtitle",
      "To ensure the most exciting assignments you need a crisp consultant CV with a spot on description of your experiences and competence."
    ),
    description: t(
      "explore.usp2.description",
      "We do recognize having multi-talented consultants with abilities widely ranging and the need of presenting them in multiple ways for different clients and projects. Momang enhances, highlights and presents your consultants in the best way possible so you can focus on critical and complex parts of selling. When a client views a consultant's CV, you'll receive a notification, giving you the opportunity to connect and close the deal."
    ),
    btn: null,
    leftImage: false,
  };
  const usp3 = {
    img: Availability,
    title: t("explore.usp3.title", "Availability."),
    subTitle: t(
      "explore.usp3.subtitle",
      "Utilization is the mother of all measurable KPIs."
    ),
    description: t(
      "explore.usp3.description",
      "Properly managing the availability and utilization of your consultants leads to happy employees, business growth, and increased profits. With Momang, it is intuitive and easily managed regardless of if you sell single consultants or teams. Plan projects, see the allocation per consultant and get a great overview of the current and coming situation. By filtering and sorting among the consultants, you’ll find the right consultant for the assignment in an instant."
    ),
    btn: null,
    leftImage: true,
  };
  const usp4 = {
    img: Analytics,
    title: t("explore.usp4.title", "Analytics."),
    subTitle: t(
      "explore.usp4.subtitle",
      "A powerful feature for tracking and forecasting your company's financial performance."
    ),
    description: t(
      "explore.usp4.description",
      "With Analytics, you can view the invoice forecast and chargeability forecast unit by unit or for the company as a whole. This allows you to make informed decisions about your business and stay on top of your financial health. See how much you can expect to invoice in the coming months, allowing you to plan your budget and allocate resources accordingly. Know how much time your consultants are charging for, and whether you are meeting your chargeability targets."
    ),
    btn: null,
    leftImage: false,
  };

  const usp5 = {
    img: CRM,
    title: t("explore.usp5.title", "Just another CRM."),
    subTitle: t(
      "explore.usp5.subtitle",
      "The world does not need another random CRM."
    ),
    description: t(
      "explore.usp5.description",
      "But having a CRM made solely for its purpose, selling consultants, will make your day easier. Collect all client data, keep track of tasks, handle incoming leads, find and present your best consultant profiles and close the deal fast and easy. Do you have partner companies? Check. Do you use freelancers or subcontractors? No problem. And remember, if you prefer another CRM-supplier we will happily help you integrate it with Momang."
    ),
    btn: null,
    leftImage: true,
  };
  const usp6 = {
    img: HubSpot,
    title: t("explore.usp6.title", "Streamline your workflow with our integration to HubSpot."),
    subTitle: t("explore.usp6.subtitle", "Manage your customers and leads in both platforms seamlessly."),
    description: t(
      "explore.usp6.description",
      "By using Momang to manage your consultants and HubSpot for your CRM needs, you'll be able to take advantage of the best of both worlds. When you create, edit or delete a lead or customer in HubSpot, it will automatically be reflected in Momang, streamlining your process and saving you time. You may also manage your leads in Momang which will be reflected back to HubSpot. This integration means less time spent juggling multiple systems and more time spent focused on what matters most - finding the perfect assignment for each of your consultants."
    ),
    btn: null,
    leftImage: false,
  };
  const usp7 = {
    img: Slack,
    title: t("explore.usp7.title", "Get notified through Slack."),
    subTitle: t("explore.usp7.subtitle", "Pling - the client just opened your consultant CV!"),
    description: t(
      "explore.usp7.description",
      "Momang + Slack = true. With notification you’ll always keep up to date with what’s going on. A potential client shows interest in a consultant - pling! A subcontractor is applying for a lead - pling! An assignment is about to end - pling! With Momang's integration with Slack, you'll always know what's happening and can take timely action. Never miss an opportunity or important update again."
    ),
    btn: null,
    leftImage: true,
  };
  const usp8 = {
    img: Wellness,
    title: t("explore.usp8.title", "Wellness."),
    subTitle: t(
      "explore.usp8.subtitle",
      "At the core of any consulting firm are its people, and it's crucial that they are happy and fulfilled in their work."
    ),
    description: t(
      "explore.usp8.description",
      "Momang helps you ensure the wellbeing of your team by allowing colleagues to rate their own satisfaction and performance. By tracking these ratings over time, you can see patterns and trends emerge and get a clear picture of the overall wellbeing of your team. This information is invaluable for identifying areas of improvement and promoting a positive and supportive work environment."
    ),
    btn: null,
    leftImage: false,
  };
  const usp9 = {
    img: Interact,
    title: t("explore.usp9.title", "Interact with the consultant profile."),
    subTitle: t("explore.usp9.subtitle", "Accelerate the sales process by allowing potential customers to interact with your consultants' CV."),
    description: t(
      "explore.usp9.description",
      "The duration of the sales cycle can make or break a deal. That's where Momang comes in. By sharing a consultant CV with a client via a link, they can provide feedback or schedule a meeting directly. This allows you to quickly gauge whether the consultant is a good fit for the assignment and move forward in the sales process. Streamline your sales cycle with Momang and close deals faster."
    ),
    btn: null,
    leftImage: true,
  };
  const usp10 = {
    img: Leads,
    title: t("explore.usp10.title", "Share leads."),
    subTitle: t(
      "explore.usp10.subtitle",
      "Expand your business with ease. Imagine being able to take on every interesting and challenging inquiry that comes your way. With Momang, it's possible."
    ),
    description: t(
      "explore.usp10.description",
      "By creating a lead and sharing it with potential subcontractors, you'll receive notifications when they express interest. All relevant information will be automatically recorded in Momang, streamlining your communication and allowing you to respond quickly and accurately. By staying in close contact with the client and being efficient and reliable, you'll set the stage for success and drive your business growth."
    ),
    btn: null,
    leftImage: false,
  };

  return (
    <>
      <Helmet>
        <title>{t("explore.meta.title", "Momang")}</title>
        <meta name="description" content={t("explore.meta.description", "Momang")} />
      </Helmet>
      <Navbar bg={"#ffd2d5f1"} />
      <Nav bg={"#ffd2d5f1"} />
      <HeroArea history={props.history} />
      <div className={classNames.upsContainer}>
        <USP details={usp1} />
        <USP details={usp2} />
        <USP details={usp3} />
        <USP details={usp4} />
        <USP details={usp5} />
        <USP details={usp6} />
        <USP details={usp7} />
        <USP details={usp8} />
        <USP details={usp9} />    
        <USP details={usp10} />  
      </div>
      <Footer />
    </>
  );
};

export default Explore;
