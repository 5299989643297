import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import classNames from "./Features.module.css";

import HeroImage from "../../assets/images/happy_guy.png";
import { FeaturesBox } from "./FeaturesBox";
import Nav from "../../components/ResponsiveNav/Nav";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Features = () => {
  const [t] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const features = t("features.features", { returnObjects: true });

  return (
    <>
      <Helmet>
        <title>{t("features.meta.title", "Momang")}</title>
        <meta name="description" content={t("features.meta.description", "Momang")} />
      </Helmet>
      <Navbar bg={"#b0d8f4f1"} />
      <Nav bg={"#b0d8f4f1"} />
      <div className={classNames.heroArea}>
        <div className={classNames.text}>
          <div className={classNames.title}>
            <h1>{t("features.title", "Let’s work together!")}</h1>
          </div>
          <div className={classNames.body}>
            <p>
              {t(
                "features.description",
                "It’s worth it. It’s free. It’s professional. It’s enterprise customized. Still unsure? Let’s do 10 minutes on the phone or drop in for a demo."
              )}
            </p>
          </div>
        </div>
        <div className={classNames.imgContainer}>
          <img src={HeroImage} alt="happy_guy" />
        </div>
      </div>
      <div className={classNames.priceContainer}>
        <div className={classNames.priceInner}>
          <FeaturesBox title={t("features.featureBoxTitle", "Tons of features")} features={features} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Features;
