import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import cn from "./Booking.module.css";

import HeroImage from "../../assets/images/popcorn.png";
import Nav from "../../components/ResponsiveNav/Nav";
import { HubSpotMeeting } from "../../components/HubSpotMeeting";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Booking = () => {
  const [t] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("booking.meta.title", "Momang")}</title>
        <meta name="description" content={t("booking.meta.description", "Momang")} />
      </Helmet>
      <Navbar bg={"#b0d8f4f1"} />
      <Nav bg={"#b0d8f4f1"} />
      <div className={cn.heroArea}>
        <div className={cn.text}>
          <div className={cn.title}>
            <h1>{t("booking.title", "15 minutes of enlightenment")}</h1>
          </div>
          <div className={cn.subTitle}>
            <h3>
              {t(
                "booking.subtitle",
                "Seeing is believing. Take a look and maybe you make a business changing decision, for the better."
              )}
            </h3>
          </div>
          <div className={cn.body}>
            <p>
              {t(
                "booking.description",
                "Pick a time and let us get to know you and your needs. Tougher decisions have been made, doing Momang or not is not one of them. It’s fun, intuitive and really supportive — much like your smartest and most likeable colleague."
              )}
            </p>
          </div>
        </div>
        <div className={cn.imgContainer}>
          <img src={HeroImage} alt="popcorn" />
        </div>
      </div>
      <div className={cn.calendarContainer}>
        <HubSpotMeeting />
      </div>
      <Footer />
    </>
  );
};

export default Booking;
