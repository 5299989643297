import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { sanityClient, urlFor } from "../../sanityClient";
import Navbar from "../../components/Navbar/Navbar";
import Nav from "../../components/ResponsiveNav/Nav";
import Footer from "../../components/Footer/Footer";
import { Link, useLocation, useParams } from "react-router-dom";
import { LinkArrowRight } from "../../components/LinkArrowRight";
import c from "./Blog.module.css";

export function Blog() {
  const { language } = useParams();
  const { search } = useLocation();
  const [t] = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [allPostsData, setAllPosts] = useState(null);
  const [allCategoriesData, setAllCategories] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(search);
    setCategory((prevCategory) => query.get("category") ?? prevCategory ?? "");
  }, [search]);

  useEffect(() => {
    if (category === null || !language) return;

    setIsLoading(true);

    sanityClient
      .fetch(
        `
        *[_type == "post" && ($category == "" || $category in categories[]->id)]
        | order(publishedAt desc)
        {
          "title": title.${language},
          slug,
          "description": description.${language},
          mainImage{
            asset->{
              _id,
              url
            },
            "alt": asset->altText
          },
          publishedAt
        }
      `,
        { category }
      )
      .then((data) => setAllPosts(data))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [language, category]);

  useEffect(() => {
    sanityClient
      .fetch(
        `
        *[_type == "category"]{
          id,
          "title": title.${language}
        }
      `
      )
      .then(setAllCategories)
      .catch(console.error);
  }, [language]);

  return (
    <>
      <Helmet>
        <title>{t("newFeatures.meta.title", "Momang")}</title>
        <meta name="description" content={t("newFeatures.meta.description", "Momang")} />
      </Helmet>
      <Navbar bg={"#ffd2d5f1"} />
      <Nav bg={"#ffd2d5f1"} />
      <div className={c.heroAreaContainer}>
        <div className={c.left}>
          <h1>{t("newFeatures.title", "This just in!")}</h1>
          <h3>{t("newFeatures.subtitle", "Under construction, we are revolutionizing competence selling.")}</h3>
        </div>
        <div className={c.right}>
          <Trans
            i18nKey="newFeatures.description"
            defaults="<strong>New value creating features are added and more is in the making.</strong><br/>Let us be clear, this is a service for you so your feedback is highly appreciated. We are just like you so let us build greatness together. Join our <strong>Slack channel</strong>, use the support function, ping us on <strong>LinkedIn</strong> or drop a line at <strong>clientsfirst@momang.io</strong>"
            components={{ strong: <strong />, br: <br /> }}
          />
        </div>
      </div>
      {allCategoriesData?.length > 0 ? (
        <div className={c.filterContainer}>
          <label className={c.filterLabel} htmlFor="category">
            {t("blog.filterCategories", "Show category")}
          </label>
          <select
            className={c.filterSelect}
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value={""}>{t("blog.filterCategoriesAll", "All")}</option>
            {allCategoriesData &&
              allCategoriesData.map((category, index) => (
                <option key={index} value={category.id}>
                  {category.title}
                </option>
              ))}
          </select>
        </div>
      ) : null}
      <div className={c.posts}>
        {isLoading && <div className={c.postsInfo}>Loading...</div>}
        {!isLoading && !allPostsData?.length && <div className={c.postsInfo}>No posts found</div>}
        {!isLoading &&
          allPostsData?.length > 0 &&
          allPostsData.map((post, index) => (
            <Link to={`${post.slug.current}`} key={index} className={c.post}>
              <div className={c.postContent}>
                {post.mainImage && (
                  <div className={c.postImage}>
                    <img src={urlFor(post.mainImage).height(250).url()} alt={post.mainImage?.alt} />
                  </div>
                )}
                <h2 className={c.postTitle}>{post.title}</h2>
                <div className={c.postDescription} dangerouslySetInnerHTML={{ __html: post.description }} />
                <div className={c.postBtn}>
                  <span>{t("newFeatures.readMore", "Read more")}</span>
                  <LinkArrowRight />
                </div>
              </div>
            </Link>
          ))}
      </div>
      <Footer />
    </>
  );
}
