import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { HubSpotChat } from "./components/HubSpotChat";
import { GoogleAnalytics } from "./components/GoogleAnalytics";
import { useTranslation } from "react-i18next";
import { LanguageMetaTags } from "./components/LanguageMetaTags";
import { LanguageRoutes } from "./LanguageRoutes";

function App() {
  const [, i18n] = useTranslation();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={`/${i18n.language}`} />} />
        <Route path="/:language/*" element={<LanguageRoutes />} />
      </Routes>
      <HubSpotChat />
      <GoogleAnalytics />
      <LanguageMetaTags />
    </Router>
  );
}

export default App;
