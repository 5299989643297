import React from "react";
import classNames from "./FeaturesBox.module.css";
import { Link } from "../../components/Link";
import { useTranslation } from "react-i18next";
import { Checkmark } from "../../components/Checkmark";

export function FeaturesBox({ title, features }) {
  const [t] = useTranslation();

  return (
    <div className={classNames.container}>
      <div className={classNames.title}>
        <h1>{title}</h1>
      </div>
      <div>
        {features?.map((feature) => (
          <>
            <FeaturesTitle title={feature.title} />
            <FeaturesList list={feature.list} />
          </>
        ))}
      </div>
      <div className={classNames.btnContainer}>
        <p>
          <Link to="/signup">{t("features.ctaSignUp", "Sign up for a 14-day trial")}</Link>
        </p>
        <p>
          <Link to="/booking">{t("features.ctaBookDemo", "Book a demo")}</Link>
        </p>
        <p>
          <a href="mailto:info@momang.io" target="_blank" rel="noreferrer">
            {t("features.ctaContact", "Contact us")}
          </a>
        </p>
      </div>
    </div>
  );
}

function FeaturesTitle({ title }) {
  return <h2>{title}</h2>;
}

function FeaturesList({ list }) {
  return (
    <ul>
      {list?.map((itm, idx) => (
        <li key={idx}>
          <Checkmark />
          {itm}
        </li>
      ))}
    </ul>
  );
}
