import React, { useState } from "react";
import { NavLink } from "../NavLink";
import { Link } from "../Link";
import classNames from "./Navbar.module.css";
import Logo from "../../assets/images/Logo/logo-primary.svg";
import LogoHover from "../../assets/images/Logo/logo-secondary-alt2.svg";
import { useTranslation } from "react-i18next";

const Navbar = ({ bg }) => {
  const [t] = useTranslation();
  const [logo, setLogo] = useState(Logo);

  return (
    <>
      <div className={classNames.container} style={{ backgroundColor: `${bg}` }}>
        <div className={classNames.inner}>
          <div className={classNames.leftNav}>
            <Link
              to="/"
              className={classNames.logo}
              onMouseEnter={() => setLogo(LogoHover)}
              onMouseLeave={() => setLogo(Logo)}
            >
              <img src={logo} alt="Logo" />
            </Link>
            <div className={classNames.nav}>
              <NavLink to="/explore">{t("navbar.explore", "Explore")}</NavLink>
              <NavLink to="/features">{t("navbar.features", "Features")}</NavLink>
              <NavLink to="/faq">{t("navbar.faq", "FAQ")}</NavLink>
            </div>
          </div>
          <div className={classNames.rightNav}>
            <div className={classNames.userAccess}>
              <NavLink to="/signup">{t("navbar.signup", "Signup")}</NavLink>
              <a href="https://app.momang.io">{t("navbar.login", "Login")}</a>
            </div>
            <div className={classNames.bookDemo}>
              <Link to="/booking">{t("navbar.booking", "Book quick demo")}</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
