import React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Signup from "./containers/Signup/Signup";
import Booking from "./containers/Booking/Booking";
import Explore from "./containers/Explore/Explore";
import Features from "./containers/Features/Features";
import Faq from "./containers/Faq/Faq";
import Landing from "./containers/Landing/Landing";
import { Blog } from "./containers/Blog/Blog";
import { BlogPost } from "./containers/Blog/BlogPost/BlogPost";

import { SUPPORTED_LANGUAGES } from "./utils";

export function LanguageRoutes() {
  const [, i18n] = useTranslation();
  const { language } = useParams();

  if (!SUPPORTED_LANGUAGES.includes(language)) {
    // In this case the :language route variable has matched an actual page url (such as "/explore"), let's
    // redirect to the correct language aware url.
    return <Navigate to={`/${i18n.language}/${language}`} />;
  }
  if (i18n.language !== language) setTimeout(() => i18n.changeLanguage(language), 0);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="signup" element={<Signup />} />
      <Route path="booking" element={<Booking />} />
      <Route path="explore" element={<Explore />} />
      <Route path="new-features" element={<Blog />} exact />
      <Route path="new-features/:postSlug" element={<BlogPost />} />
      <Route path="features" element={<Features />} />
      <Route path="faq" element={<Faq />} />
      <Route path="/*" element={<Navigate to={`/${i18n.language}`} />} />
    </Routes>
  );
}
