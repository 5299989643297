import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function createLink(url, languageCode) {
  const link = document.createElement("link");
  link.rel = "alternate";
  link.hreflang = languageCode;
  link.href = url;
  return link;
}

export function LanguageMetaTags() {
  const location = useLocation();

  useEffect(() => {
    if (!location?.pathname) return;

    const head = document.head;
    const previousLinks = head.querySelectorAll("[hreflang]");
    previousLinks.forEach((l) => head.removeChild(l));

    const path = `${window.location}`;
    head.append(createLink(path.replace("/sv", "/en"), "en"));
    head.append(createLink(path.replace("/en", "/sv"), "sv"));
  }, [location]);

  return null;
}
