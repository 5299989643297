import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export function GoogleAnalytics() {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  }, []);

  useEffect(() => {
    if (!pathname) return;
    ReactGA.set({ subDomain: "www" });
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);

  return null;
}
