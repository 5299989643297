import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import { clientConfig, sanityClient, urlFor } from "../../../sanityClient";
import Navbar from "../../../components/Navbar/Navbar";
import Nav from "../../../components/ResponsiveNav/Nav";
import Footer from "../../../components/Footer/Footer";
import { Link } from "../../../components/Link";
import { LinkArrowRight } from "../../../components/LinkArrowRight";
import c from "./BlogPost.module.css";

const serializers = {
  types: {
    image: ({ node }) => {
      return (
        <figure>
          <img src={urlFor(node).width(1000).fit("crop").auto("format").url()} alt={node.alt} />
          {node.description ? <figcaption>{node.description}</figcaption> : null}
        </figure>
      );
    },
  },
};

export function BlogPost() {
  const [t, i18n] = useTranslation();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { language, postSlug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $postSlug]{
          "title": title.${language},
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            "alt": asset->altText
          },
          "body": body.${language}[]{
            ...,
            _type == "image" => {
              asset->{
                _id,
                url
              },
              "alt": asset->altText,
            }
          },
          "authorName": author->name,
          "authorImage": author->image,
          "categories": categories[]->{
            id,
            "title": title.${language},
          },
          publishedAt
       }`,
        { postSlug }
      )
      .then((data) => setPost(data[0]))
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [postSlug, language]);

  if (isLoading) return <div>Loading...</div>;
  if (!post) return <Navigate to={`/${i18n.language}/new-features`} />;

  return (
    <>
      <Helmet>
        <title>{post.meta?.title}</title>
        <meta name="description" content={post.meta?.description} />
      </Helmet>
      <Navbar bg={"#ffd2d5f1"} />
      <Nav bg={"#ffd2d5f1"} />
      <div className={c.container}>
        <div className={c.post}>
          {post.mainImage ? (
            <div className={c.mainImage}>
              <img src={urlFor(post.mainImage).width(1000).auto("format").url()} alt={post.mainImage.alt} />
            </div>
          ) : null}
          <div className={c.authorDateContainer}>
            {post.authorImage ? <img src={urlFor(post.authorImage).width(100).url()} alt="Author is Kap" /> : null}
            {post.authorName}
            {post.publishedAt ? (
              <>
                <span className={c.authorDateDivider} />
                {new Date(post.publishedAt).toLocaleDateString()}
              </>
            ) : null}
          </div>
          {post.categories?.length ? (
            <div className={c.categoriesContainer}>
              {post.categories.map((category) => (
                <Link to={`/new-features?category=${category.id}`} key={category.id} className={c.category}>
                  {category.title}
                </Link>
              ))}
            </div>
          ) : null}
          {post.body ? (
            <div className={c.blockContent}>
              <BlockContent
                blocks={post.body}
                projectId={clientConfig.projectId}
                dataset={clientConfig.dataset}
                serializers={serializers}
              />
            </div>
          ) : null}
          <div className={c.goBackLink}>
            <Link to="/new-features">
              <span>{t("newFeatures.allNewFeatures", "Show all new features")}</span>
              <LinkArrowRight size="md" />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
