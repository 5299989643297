import React from "react";
import classNames from "./Footer.module.css";
import Logo from "../../assets/images/Logo/logo-secondary-alt1.svg";
import { Link as RrdLink, useLocation } from "react-router-dom";
import { Link } from "../Link";
import { LinkedInIcon } from "../LinkedInIcon";
import { FacebookIcon } from "../FacebookIcon";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const [t] = useTranslation();
  const location = useLocation();

  return (
    <>
      <div className={classNames.container}>
        <div className={classNames.inner}>
          <div className={classNames.flexItem}>
            <div className={classNames.logoContainer}>
              <img src={Logo} alt="Logo" className={classNames.logo} />
            </div>
            <div className={classNames.body}>
              <p>
                {t(
                  "footer.summary",
                  "Momang provides eye catching CVs at your fingertips and a clear overview of availability, competence and sales communication in order to never, ever, miss a deal."
                )}
              </p>
            </div>
          </div>
          <div className={classNames.flexItem}>
            <div className={classNames.header}>
              <p>{t("footer.language", "Language")}</p>
            </div>
            <div className={classNames.body}>
              <ul>
                <li>
                  <RrdLink to={location.pathname.replace("/en", "/sv")}>{t("footer.swedish", "Swedish")}</RrdLink>
                </li>
                <li>
                  <RrdLink to={location.pathname.replace("/sv", "/en")}>{t("footer.english", "English")}</RrdLink>
                </li>
              </ul>
            </div>
          </div>
          <div className={classNames.flexItem}>
            <div className={classNames.header}>
              <p>{t("footer.product", "Product")}</p>
            </div>
            <div className={classNames.body}>
              <ul>
                <li>
                  <Link to="/explore">{t("footer.explore", "Explore")}</Link>
                </li>
                <li>
                  <Link to="/features">{t("footer.features", "Features")}</Link>
                </li>
                <li>
                  <Link to="/booking">{t("footer.booking", "Book quick demo")}</Link>
                </li>
                <li>
                  <Trans
                    i18nKey="footer.policy"
                    defaults="<a href='/documents/privacy-policy.pdf'>Privacy Policy</a>"
                    // eslint-disable-next-line
                    components={{ a: <a target="_blank" /> }}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey="footer.terms"
                    defaults="<a href='/documents/terms-of-service-2023-07-04.pdf'>Terms of Service</a>"
                    // eslint-disable-next-line
                    components={{ a: <a target="_blank" /> }}
                  />
                </li>
                <li>
                  <Trans
                    i18nKey="footer.dpa"
                    defaults="<a href='/documents/data-processing-agreement.pdf'>Data Processing Agreement</a>"
                    // eslint-disable-next-line
                    components={{ a: <a target="_blank" /> }}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className={classNames.flexItem}>
            <div className={classNames.header}>
              <p>{t("footer.getStarted", "Get started")}</p>
            </div>
            <div className={classNames.body}>
              <ul>
                <li>
                  <Link to="/signup">{t("footer.signup", "Signup")}</Link>
                </li>
                <li>
                  <a href="https://app.momang.io">{t("footer.login", "Login")}</a>
                </li>
              </ul>
            </div>
          </div>
          <div className={classNames.flexItem}>
            <div className={classNames.header}>
              <p>{t("footer.address", "Address")}</p>
            </div>
            <div className={classNames.body}>
              <ul>
                <li>
                  <p>Barnhusgatan 3</p>
                </li>
                <li>
                  <p>111 23 Stockholm</p>
                </li>
                <li>
                  <p>
                    <a href="mailto:info@momang.io">info@momang.io</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className={classNames.flexItem}>
            <div className={classNames.header}>
              <p>{t("footer.connect", "Connect")}</p>
            </div>
            <div className={classNames.body}>
              <ul>
                <li className={classNames.contact}>
                  <a href="https://www.linkedin.com/company/momang" target="_blank" rel="noreferrer">
                    <LinkedInIcon />
                    LinkedIn
                  </a>
                </li>
                <li className={classNames.contact}>
                  <a href="https://www.facebook.com/Momang-103175054858092" target="_blank" rel="noreferrer">
                    <FacebookIcon />
                    Facebook
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
