import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import HeroArea from "./HeroArea/HeroArea";
import classNames from "./Landing.module.css";
import Footer from "../../components/Footer/Footer";

import Donut from "./../../assets/images/donuts.jpg";
import Chess from "./../../assets/gifs/chess_final.gif";
import IceCream from "./../../assets/gifs/ice_cream.gif";

import USP from "../../components/USP/USP";
import { Slider } from "./Slider/Slider";
import FooterArea from "./FooterArea/FooterArea";
import Nav from "../../components/ResponsiveNav/Nav";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Video } from "./VideoPlayer/Video";

const Landing = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const usps = [
    {
      img: Donut,
      title: t("landing.usp1.title", "Design unique and tailor made profiles"),
      subTitle: t(
        "landing.usp1.subtitle",
        "An easy to use profile editor — the right skill for the right client just in time."
      ),
      description: t(
        "landing.usp1.description",
        "Put your relation to your clients and colleagues first and let Momang provide you with a well written, fully updated and ready to present Consultant Profile at any given moment. Imagine having that colleague that creates beautiful and tailor made proposals that exceeds client expectations and you have Momang."
      ),
      btn: t("landing.usp1.button", "Book a demo"),
      leftImage: true,
      url: "/booking",
    },
    {
      img: Chess,
      title: t("landing.usp2.title", "One step ahead"),
      subTitle: t("landing.usp2.subtitle", "Manage availability and maximize it."),
      description: t(
        "landing.usp2.description",
        "Planning for your key competences availability and utilization is business critical for thriving colleagues, growth and bottom line. With Momang it is intuitive and easily managed — have a look at present clients, pricing now and backwards, make business plans for growth and plan absence."
      ),
      btn: t("landing.usp2.button", "See features"),
      leftImage: false,
      url: "/features",
    },
    {
      img: IceCream,
      title: t("landing.usp3.title", "Act fast, help clients and partner up with the best"),
      subTitle: t("landing.usp3.subtitle", "A CRM made for selling consultants."),
      description: t(
        "landing.usp3.description",
        "Everything you will need to evolve your business. Keep track of client conversations, leads, consultants and subcontractors. Let Momang back you up with sales intelligence and boost your team with relevant KPIs. Know who to call and what action to take in every moment. We know that fast, timely and spot on proposals is key for thriving consultants and happy clients."
      ),
      btn: t("landing.usp3.button", "Test for free"),
      leftImage: true,
      url: "/signup",
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("landing.meta.title", "Momang")}</title>
        <meta name="description" content={t("landing.meta.description", "Momang")} />
      </Helmet>
      <Navbar bg={"#ffd2d5f1"} />
      <Nav bg={"#ffd2d5f1"} />
      <HeroArea history={props.history} />
      <Video />
      <div className={classNames.scroller}>
        <div className={classNames.usp}>
          {usps.map((usp, index) => (
            <USP key={index} id={`usp${index}`} details={usp} history={props.history} />
          ))}
        </div>
        <Slider />
      </div>
      <FooterArea history={props.history} />
      <Footer />
    </>
  );
};

export default Landing;
