// eslint-disable-next-line
import React, { useEffect } from "react";

export function HubSpotMeeting() {
  useEffect(() => {
    const hsScript = document.createElement("script");
    hsScript.id = "hs-script-loader-meeting";
    hsScript.type = "text/javascript";
    hsScript.async = true;
    hsScript.defer = true;
    hsScript.src = "//static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    document.body.appendChild(hsScript);
  }, []);

  return <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/bernhard5?embed=true" />;
}
