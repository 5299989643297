import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import cn from "./Faq.module.css";
import "./FaqList.css";

import HeroImage from "../../assets/images/hopp_dude_new.png";
import Nav from "../../components/ResponsiveNav/Nav";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import FaqList from "react-faq-component";

const Faq = () => {
  const [t] = useTranslation();
  const [faqs, setFaqs] = useState([]);

  const styles = {
    titleTextSize: "48px",
    rowTitleTextSize: "24px",
    rowContentPaddingTop: "5px",
    rowContentPaddingBottom: "5px",
    rowContentTextSize: "18px",
  };

  useEffect(() => {
    setFaqs(t("faq.faqlists", { returnObjects: true, defaultValue: [] }));
  }, [t]);

  return (
    <>
      <Helmet>
        <title>{t("faq.meta.title", "Momang")}</title>
        <meta name="description" content={t("faq.meta.description", "Momang")} />
      </Helmet>
      <Navbar bg={"#b0d8f4f1"} />
      <Nav bg={"#b0d8f4f1"} />
      <div className={cn.heroArea}>
        <div className={cn.text}>
          <div className={cn.title}>
            <h1>{t("faq.title", "Frequently asked questions")}</h1>
          </div>
          <div className={cn.subTitle}>
            <h3>{t("faq.subtitle", "")}</h3>
          </div>
          <div className={cn.body}>
            <p>
              {t(
                "faq.description",
                "Pick a time and let us get to know you and your needs. Tougher decisions have been made, doing Momang or not is not one of them. It’s fun, intuitive and really supportive — much like your smartest and most likeable colleague."
              )}
            </p>
          </div>
        </div>
        <div className={cn.imgContainer}>
          <img src={HeroImage} alt="faq" />
        </div>
      </div>
      <div className={cn.faqContainer}>
        {faqs && faqs.map((faq, i) => <FaqList styles={styles} data={faq} key={i} />)}
      </div>
      <Footer />
    </>
  );
};

export default Faq;
