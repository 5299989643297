import React from "react";
import classNames from "./FooterArea.module.css";
import { GetStartedButton } from "../Button/GetStartedButton";
import FooterGif from "../../../assets/gifs/balloons-1100x1000-30fps.webp";
import { useTranslation, Trans } from "react-i18next";

const FooterArea = () => {
  const [t] = useTranslation();

  return (
    <div className={classNames.container}>
      <div className={classNames.text}>
        <div className={classNames.title}>
          <h1>{t("landing.footer.title", "Tailor made for you. And us.")}</h1>
        </div>
        <div className={classNames.description}>
          <Trans
            i18nKey="landing.footer.description"
            defaults="<p><strong>We offer an automated and personalised service. We keep you up to date, help you enhance your skills, plan your availability and make sure you never, ever, miss a deal.</strong></p><p>Momangs users are consultancies in tech, management, engineering, finance, law firms, architects and community builders.</p><p>By helping you stretch beyond your perceived level of confidence we accelerate your development of competence and make sure it thrives.</p><p>We know how you feel - what drives you, excites you and what bothers you <strong>— because we are just like you.</strong></p>"
            components={{ p: <p />, strong: <strong /> }}
          />
        </div>
        <div>
          <GetStartedButton />
        </div>
      </div>
      <div className={classNames.image}>
        <img src={FooterGif} alt="Girl" />
      </div>
    </div>
  );
};

export default FooterArea;
