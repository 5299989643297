import React, { useEffect, useRef, useState } from "react";
import c from "./Slider.module.css";

import Bredvid from "../../../assets/images/slideLogos/bredvid_logo_lightblue.png";
import MPYADigital from "../../../assets/images/slideLogos/mpya_logo_lightblue.png";
import TheodoraTech from "../../../assets/images/slideLogos/theodora_lightblue.png";
import { useTranslation } from "react-i18next";

export function Slider() {
  const [t] = useTranslation();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const slides = useRef([
    {
      text: t(
        "landing.slider.slide1.text",
        "“Onboarding, business overview and finding the right consultants are made so easy with Momang!”"
      ),
      client: t("landing.slider.slide1.client", "Christoffer Lindblom"),
      company: t("landing.slider.slide1.company", "Brevdvid"),
      logo: Bredvid,
      logoStyle: { height: "50px" },
    },
    {
      text: t("landing.slider.slide2.text", "“Momang works so well for us. This is such a game changer!”"),
      client: t("landing.slider.slide2.client", "Christoffer Öberg"),
      company: t("landing.slider.slide2.company", "MPYA Digital"),
      logo: MPYADigital,
    },
    {
      text: t("landing.slider.slide3.text", "“Momang is like my best friend at work!“"),
      client: t("landing.slider.slide3.client", "Markus Silberstein Hont"),
      company: t("landing.slider.slide3.company", "Theodora Tech"),
      logo: TheodoraTech,
    },
  ]);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlideIndex((activeSlideIndex + 1) % slides.current.length);
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [activeSlideIndex]);

  function goToSlideIndex(index) {
    setActiveSlideIndex(index);
  }

  const activeSlide = slides.current[activeSlideIndex];
  return (
    <div className={c.container}>
      <div className={c.testimonial}>
        <h1>{t("landing.slider.title", "You're in good company")}</h1>
      </div>
      <div className={c.slides}>
        {slides.current.map((s, i) => (
          <div key={i} className={i === activeSlideIndex ? c.activeSlide : c.slide}>
            <div className={c.slideText}>
              <p>{activeSlide.text}</p>
            </div>
            <div className={c.client}>
              <div className={c.companyLogo}>
                <img src={activeSlide.logo} alt="Logo" style={activeSlide.logoStyle} />
              </div>
              <div className={c.clientInfo}>
                <p>{activeSlide.client}</p>
                <p>{activeSlide.company}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={c.dots}>
        {slides.current.map((s, i) => (
          <span key={i} className={i === activeSlideIndex ? c.active : ""} onClick={() => goToSlideIndex(i)} />
        ))}
      </div>
    </div>
  );
}
