import React from "react";
import cn from "./Video.module.css";
import ReactPlayer from "react-player/file";
import { PlayButton } from "./PlayButton";
import intro from "../../../assets/videos/momang-intro-1280.mp4";

export function Video() {
  return (
    <div className={cn.playerContainer}>
      <div className={cn.playerBackdrop}>
        <ReactPlayer
          url={intro}
          width="100%"
          height="100%"
          // style={{ maxWidth: "100%" }}
          controls
          playing
          light
          stopOnUnmount
          playIcon={<PlayButton />}
        />
      </div>
    </div>
  );
}
