import React from "react";
import classNames from "./HeroArea.module.css";

import HeroImage from "../../../assets/images/kick_tjej.png";
import { GetStartedButton } from "../Button/GetStartedButton";
import { LinkArrowDown } from "../../../components/LinkArrowDown";
import { useTranslation, Trans } from "react-i18next";

const HeroArea = () => {
  const [t] = useTranslation();
  return (
    <div className={classNames.container}>
      <div className={classNames.text}>
        <div className={classNames.title}>
          <h1>{t("landing.hero.title", "Make competence thrive")}</h1>
        </div>
        <div className={classNames.description}>
          <Trans
            i18nKey="landing.hero.description"
            defaults="<p>Momang provides eye catching consultant profiles at your fingertips and a clear overview of availability, competence and sales communication in order to <strong>never, ever, miss a deal.</strong></p><p>A full ecosystem for all services used by consultants.</p>"
            components={{ p: <p />, strong: <strong /> }}
          />
        </div>
        <div>
          <GetStartedButton />
        </div>
      </div>
      <div className={classNames.image}>
        <img src={HeroImage} alt="Girl" />
      </div>
      <a href="#usp0">
        <div className={classNames.arrow}>
          <LinkArrowDown />
        </div>
      </a>
    </div>
  );
};

export default HeroArea;
