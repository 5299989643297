import React from "react";

export function LinkArrowDown() {
  return (
    <svg width="20" height="32" viewBox="0 0 20 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6865 32H9.31395C8.34906 27.1413 4.87907 24.3026 0.000244141 23.8977L0.308285 20.8276C4.57103 21.1301 7.49288 23.1546 8.79753 27.0745H9.07386L8.55744 0L11.4431 0L10.9629 27.0745H11.203C12.5076 23.1591 15.4295 21.1346 19.7239 20.8276L20.0002 23.8977C15.1214 24.3026 11.6469 27.1413 10.6865 32ZM9.06933 27.9511V27.8843V27.9511Z"
        fill="#1D299A"
      />
    </svg>
  );
}
