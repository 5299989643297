import React from "react";
import cn from "./PlayButton.module.css";
import { useTranslation } from "react-i18next";

export function PlayButton() {
  const [t] = useTranslation();

  return (
    <div className={cn.playButtonContainer}>
      <div className={cn.playButton}>
        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 9L2.22521e-07 17.6603L1.59763e-08 0.339746L15 9Z" fill="#1D299A" />
        </svg>
        <span>{t("landing.playVideo", "Play video")}</span>
      </div>
    </div>
  );
}
