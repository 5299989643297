import React from "react";
import classNames from "./HeroArea.module.css";

import Bird from "../../../assets/images/bird_clean.png";
import { LinkArrowRight } from "../../../components/LinkArrowRight";
import { Link } from "../../../components/Link";
import { useTranslation } from "react-i18next";

const HeroArea = () => {
  const [t] = useTranslation();

  return (
    <div className={classNames.container}>
      <div className={classNames.text}>
        <div className={classNames.title}>
          <h1>{t("explore.hero.title", "Explore the features")}</h1>
        </div>
        <div className={classNames.description}>
          <p>
            <strong>{t("explore.hero.subtitle", "This is how we will make you love Momang!")}</strong>
          </p>
          <br />
          <p>
            {t(
              "explore.hero.description",
              "Everything we do is about creating the most beloved service for making competence thrive, maximize growth and well-being and at the same time having all you need for best selling competence."
            )}
          </p>
        </div>
        <Link to="/new-features" className={classNames.link}>
          <span>
            {t("explore.hero.ctaButton", "See newly added features")} <LinkArrowRight />
          </span>
        </Link>
      </div>
      <div className={classNames.image}>
        <img src={Bird} alt="Bird" />
      </div>
    </div>
  );
};

export default HeroArea;
