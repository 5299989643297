import React from "react";
import classNames from "./USP.module.css";
import { Link } from "../Link";

const USP = ({ id, details }) => {
  return (
    <div className={classNames.container} id={id}>
      <div className={details.leftImage ? `${classNames.image}` : `${classNames.image} ${classNames.left}`}>
        <img src={details.img} alt="donut" />
      </div>
      <div className={classNames.text}>
        <h1> {details.title} </h1>
        <h3> {details.subTitle} </h3>
        <p> {details.description} </p>
        {details.btn ? (
          <Link className={classNames.btn} to={details.url}>
            <span>{details.btn}</span>
            <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20 5.74409V6.59062C16.9633 7.18571 15.1891 9.32577 14.936 12.3347L13.0172 12.1447C13.2063 9.51575 14.4716 7.71374 16.9216 6.90912V6.73869L0 7.05719L0 5.27753L16.9216 5.57367V5.4256C14.4744 4.62098 13.2091 2.81896 13.0172 0.170423L14.936 0C15.1891 3.00894 16.9633 5.1518 20 5.74409ZM17.4694 6.74149H17.4277H17.4694Z"
                fill="#1D299A"
              />
            </svg>
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default USP;
