import React, { useState } from "react";
import { Link } from "../Link";
import classNames from "./Nav.module.css";
import Logo from "../../assets/images/Logo/logo-primary.svg";
import Menu from "./Menu/Menu";

const Nav = ({ bg }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Menu menu={showMenu} close={() => setShowMenu(false)} />
      <div className={classNames.container} style={{ backgroundColor: `${bg}` }}>
        <div>
          <Link to="/" className={classNames.logo}>
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div id="responsiveMenu" className={classNames.btn} onClick={() => setShowMenu(true)}>
          <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="30" y2="1" stroke="#1D299A" strokeWidth="2" />
            <line y1="9.81641" x2="30" y2="9.81641" stroke="#1D299A" strokeWidth="2" />
            <line y1="19" x2="30" y2="19" stroke="#1D299A" strokeWidth="2" />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Nav;
