// eslint-disable-next-line
import React, { useEffect } from "react";

const disableHubSpotChat = process.env.REACT_APP_DISABLE_HUBSPOT_CHAT === "true";

export function HubSpotChat() {
  useEffect(() => {
    if (disableHubSpotChat) return;
    const hsScript = document.createElement("script");
    hsScript.id = "hs-script-loader";
    hsScript.type = "text/javascript";
    hsScript.async = true;
    hsScript.defer = true;
    hsScript.src = "//js.hs-scripts.com/8356269.js";
    document.body.appendChild(hsScript);
  }, []);

  return null;
}
