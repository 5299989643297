import React, { useEffect, useRef } from "react";
import cn from "./SignupSuccess.module.css";
import lottie from "lottie-web";
import signupConfetti from "../../assets/animations/signup_confetti.json";
import signupCheck from "../../assets/animations/signup_check.json";
import { Trans } from "react-i18next";

export function SignupSuccess() {
  const confettiDiv = useRef();
  const checkDiv = useRef();

  useEffect(() => {
    const confetti = lottie.loadAnimation({
      container: confettiDiv.current,
      animationData: signupConfetti,
    });
    confetti.addEventListener("loopComplete", () => confetti.stop());
    lottie.loadAnimation({
      container: checkDiv.current,
      animationData: signupCheck,
      loop: false,
    });
  }, []);

  return (
    <div className={cn.container}>
      <Trans
        i18nKey="signup.success"
        defaults="<h1>Gr8!<br />Go check<br />your inbox</h1><p>/Team Momang</p>"
        // eslint-disable-next-line
        components={{ h1: <h1 />, br: <br />, p: <p /> }}
      />
      <div ref={checkDiv} className={cn.signupCheck} />
      <div ref={confettiDiv} className={cn.signupConfetti} />
    </div>
  );
}
