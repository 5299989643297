import React, { useEffect, useState } from "react";
import axios from "axios";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Nav from "../../components/ResponsiveNav/Nav";
import { LinkArrowRight } from "../../components/LinkArrowRight";

import classNames from "./Signup.module.css";
import SignupPageImage from "../../assets/images/hopp_dude_new.png";
import { SignupSuccess } from "../../components/SignupSuccess/SignupSuccess";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Signup = () => {
  const [t] = useTranslation();
  const [signupData, setSignupData] = useState({ plan: "premium" });
  const [errorMessageKey, setErrorMessageKey] = useState(null);
  const [signupInProgress, setSignupInProgress] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function updateSignupData(e) {
    const { id, value } = e.target;
    setSignupData(Object.assign({}, signupData, { [id]: value }));
    setErrorMessageKey(null);
  }

  async function createOrganization() {
    if (!signupData.firstUserName || !signupData.firstUserEmail || !signupData.organizationName || !signupData.plan) {
      setErrorMessageKey("signup.form.validationErrorMessage");
      return;
    }
    try {
      setSignupInProgress(true);
      await axios.post(`${process.env.REACT_APP_MOMANG_BACKEND_ENDPOINT}/organizations`, signupData);
      setSignupSuccess(true);
    } catch (error) {
      console.error("Could not create organization.", error);
      setErrorMessageKey("signup.form.generalErrorMessage");
    } finally {
      setSignupInProgress(false);
    }
  }

  const signupForm = (
    <div className={classNames.form}>
      <div className={classNames.title}>
        <p>{t("signup.form.title", "To get started, we need a few details.")}</p>
      </div>
      <div className={classNames.signupFrom}>
        <input
          id="firstUserName"
          value={signupData?.firstUserName || ""}
          type="text"
          placeholder={t("signup.form.firstUserName", "Your name")}
          onChange={updateSignupData}
          disabled={signupInProgress}
        />
        <input
          id="firstUserEmail"
          value={signupData?.firstUserEmail || ""}
          type="email"
          placeholder={t("signup.form.firstUserEmail", "Your e-mail address")}
          onChange={updateSignupData}
          disabled={signupInProgress}
        />
        <input
          id="organizationName"
          value={signupData?.organizationName || ""}
          type="text"
          placeholder={t("signup.form.organizationName", "Company name")}
          onChange={updateSignupData}
          disabled={signupInProgress}
        />
      </div>
      {errorMessageKey && (
        <p className={classNames.errorMsg}>
          {t(errorMessageKey, "Dang! Something went wrong. Please try again later.")}
        </p>
      )}
      <div className={classNames.btn}>
        <button onClick={createOrganization} disabled={signupInProgress}>
          {t("signup.form.submit", "Let's go!")}
        </button>
        <p>{t("signup.form.freeTrial", "Start a 14 days free trial - no strings attached!")}</p>
      </div>
      <div className={classNames.formFooter}>
        <p>
          <Trans
            i18nKey="signup.form.policy"
            defaults="By signing up, you accept the <a href='/documents/privacy-policy.pdf'>Privacy Policy</a> and the <a href='/documents/terms-of-service-2023-07-04.pdf'>Terms of Service</a>."
            // eslint-disable-next-line
            components={{ a: <a target="_blank" /> }}
          />
        </p>
      </div>
    </div>
  );
  return (
    <>
      <Helmet>
        <title>{t("signup.meta.title", "Momang")}</title>
        <meta name="description" content={t("signup.meta.description", "Momang")} />
      </Helmet>
      <Navbar bg={"#b0d8f4f1"} />
      <Nav bg={"#b0d8f4f1"} />
      <div className={classNames.container}>
        <div className={classNames.formContainer}>
          <div className={classNames.header}>
            <h1>{t("signup.title", "Welcome to the team")}</h1>
          </div>
          <div className={classNames.inner}>
            <div className={classNames.text}>
              <div className={classNames.title}>
                <p>{t("signup.subtitle", "Let’s do this. Get everything you need.")}</p>
              </div>
              <div className={classNames.numberedList}>
                <div className={classNames.number}>
                  <h1>1</h1>
                </div>
                <div className={classNames.nt}>
                  <p>
                    {t(
                      "signup.step1",
                      "We facilitate the pitch for your team and ensure a smooth and joyful onboarding experience."
                    )}
                  </p>
                </div>
              </div>
              <div className={classNames.numberedList}>
                <div className={classNames.number}>
                  <h1>2</h1>
                </div>
                <div className={classNames.nt}>
                  <p>{t("signup.step2", "Automatic transition in case you have anything that resembles Momang :)")}</p>
                </div>
              </div>
              <div className={classNames.numberedList}>
                <div className={classNames.number}>
                  <h1>3</h1>
                </div>
                <div className={classNames.nt}>
                  <p>
                    {t(
                      "signup.step3",
                      "Without breaking a sweat you are fully equipped to enjoy the most of your time together with your highly competent colleagues."
                    )}
                  </p>
                </div>
              </div>
              <div className={classNames.footerBtn}>
                <p>
                  <a href="https://app.momang.io">
                    {t("signup.login", "Already have an account? Log in.")} <LinkArrowRight />
                  </a>
                </p>
              </div>
            </div>
            {signupSuccess ? <SignupSuccess /> : signupForm}
          </div>
          <div className={classNames.image}>
            <img src={SignupPageImage} alt="hopp_dude" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
