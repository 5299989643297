import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

const clientConfig = {
  projectId: "0nue77ko",
  dataset: process.env.REACT_APP_STAGE === "production" ? "production" : "test",
  useCdn: true,
  apiVersion: "2023-09-15",
};
const sanityClient = createClient(clientConfig);

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export { sanityClient, clientConfig, urlFor };
